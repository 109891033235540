<template>
	<Layout>
		<div class="slider-area slider-style-1 variation-default height-500 bg_image"
				 :style="{'background-image': `url(${require(`@/assets/images/_new/2.png`)})`}">
		</div>
		<div class="about-area about-style-4 rn-section-gap cloud-desc bg-light">
			<div class="container">
				<div class="row align-items-center mb--30" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-12">
						<div class="content">
							<div class="inner text-center">
								<h3 class="title"><strong>无线投屏</strong></h3>
							</div>
						</div>
					</div>
				</div>
				<div class="row align-items-center" data-aos="fade-up" data-aos-delay="200">
					<div class="col-lg-12">
						<div class="content">
							以无线投屏技术为核心竞争力，专注于满足企业用户在智能化会议协同方面的需求，致力于开发稳定可靠、高效运行且质量上乘的会议场景解决方案。
						</div>
					</div>
				</div>
				<div class="row mt--30">
					<div v-for="(item, idx) in productData" class="col-lg-3 col-md-6 col-sm-12 col-12 padding--10" data-aos="fade-up" :data-aos-delay="100 + 100 * idx">
						<div class="card-box card-style-1 text-left" style="background: linear-gradient(180deg, rgba(106,218,241,0.2) 0%, rgba(142,242,249,0.01) 100%, rgba(2,0,36,1) 100%);
">
							<div class="inner">
								<div class="full-image">
									<img :src="item.image" :alt="item.title">
								</div>
								<div class="content mt--20 mb--20">
									<h4 class="title">
										{{ item.title }}
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt--30" data-aos="fade-up" data-aos-delay="100">
					<div class="text-center">
						<h4 class="title"><strong>版本对比</strong></h4>
					</div>
					<table class="table table-bordered table-hover">
						<thead>
							<tr>
								<th>功能</th>
								<th>FancyCast 无线投屏系统<br>旗舰版</th>
								<th>CherDrop 极炘无线投屏系统<br>基础版</th>
								<th>传统会议室投屏系统</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div class="func-description" style="font-size: 18px">
										<div class="func-title">动态投屏</div>
										<div class="func-items">
											<ul class="func-feature-list" style="margin-bottom: 0">
												<li class="adv123">
													多投一：多个用户向同一屏幕投放内容
												</li>
												<li class="adv123">
													动态布局：分屏布局显示或同屏内容切换
												</li>
											</ul>
										</div>
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="func-description" style="font-size: 18px">
										<div class="func-title">集中运维</div>
										<div class="func-items">
											<ul class="func-feature-list" style="margin-bottom: 0">
												<li class="adv123">
													通过集中管理平台对所有连接设备进行统一运维管理
												</li>
											</ul>
										</div>
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="func-description" style="font-size: 18px">
										<div class="func-title">节能模式</div>
										<div class="func-items">
											<ul class="func-feature-list" style="margin-bottom: 0">
												<li class="adv123">
													支持节能计划
												</li>
												<li class="adv123">
													屏幕自动休眠
												</li>
											</ul>
										</div>
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="func-description" style="font-size: 18px">
										<div class="func-title">多协议支持</div>
										<div class="func-items">
											<ul class="func-feature-list" style="margin-bottom: 0">
												<li class="adv123">
													支持web、客户端等多种投屏方式
												</li>
												<li class="adv123">
													支持多种设备投屏（iOS/Android/Mac/Windows）
												</li>
											</ul>
										</div>
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="func-description" style="font-size: 18px">
										<div class="func-title">多种部署方式</div>
										<div class="func-items">
											<ul class="feature-list" style="margin-bottom: 0">
												<li class="adv123">
													支持本地部署
												</li>
												<li class="adv123">
													持SaaS部署
												</li>
											</ul>
										</div>
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="func-description" style="font-size: 18px">
										<div class="func-title">高清4K高清画质</div>
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="func-description" style="font-size: 18px">
										<div class="func-title">支持智能互联与远程控制</div>
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div class="func-description" style="font-size: 18px">
										<div class="func-title">AI大模型联动</div>
										<div class="func-items">
											<ul class="func-feature-list" style="margin-bottom: 0">
												<li class="adv123">
													支持AI会议助理
												</li>
												<li class="adv123">
													支持语音控制联动
												</li>
											</ul>
										</div>
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="check-circle" size="30" icon-class="color-light-green" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
								<td>
									<div class="func">
										<Icon name="x-circle" size="30" class="color-secondary" />
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>


		<div class="about-area about-style-4 rn-section-gap">
			<div class="container">
				<div class="row row--20" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-7 mt--20 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="inner">
								<h4 class="title"><strong>全端支持</strong></h4>
								<div class="description" style="font-size: 18px">
									<div>
										在众多智能显示终端的市场中，各厂家设备硬件配置千变万化。我们的无线投屏技术始终致力于与所有主流智能电视进行深度的软硬件兼容适配，旨在为用户带来前所未有的极致投屏享受。
									</div>
									<div class="mt--10">
										推出的FancyCast 无线投屏系统旗舰版、CherDrop 极炘无线投屏系统基础版已经成功助力数万台智能电视解锁投屏功能，让大屏互动触手可及。
									</div>
									<div class="mt--10">
										无论是Windows、macOS系统电脑，还是Android、iOS手机，都可以通过我们的无线投屏系统实现一键投屏，轻松享受跨屏互联的便捷与乐趣。
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-5">
						<img class="product-img" src="../assets/images/cherdrop/d1.png" style="width: 100%" />
					</div>
				</div>
			</div>
		</div>

		<separator />

		<div class="about-area about-style-4 rn-section-gap">
			<div class="container">
				<div class="row row--20" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-5">
						<img class="product-img" src="../assets/images/cherdrop/d3.png" style="width: 100%" />
					</div>
					<div class="col-lg-7 mt--20 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="inner">
								<h4 class="title"><strong>投屏码连接投屏</strong></h4>
								<div class="description" style="font-size: 18px">
									<div class="mt--10">
										<ul class="feature-list">
											<li class="adv">
												<div class="icon" style="font-size: 14px">1</div>
												<div class="title-wrapper">
													<h4 class="title">适用于Windows、macOS系统电脑，Android、iOS手机及平板设备。</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon" style="font-size: 14px">2</div>
												<div class="title-wrapper">
													<h4 class="title">电脑、手机、平板设备连接网络</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon" style="font-size: 14px">3</div>
												<div class="title-wrapper">
													<h4 class="title">访问投屏网址</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon" style="font-size: 14px">4</div>
												<div class="title-wrapper">
													<h4 class="title">输入投屏码，点击投屏按钮</h4>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<separator />

		<div class="about-area about-style-4 rn-section-gap">
			<div class="container">
				<div class="row row--20" data-aos="fade-up" data-aos-delay="100">
					<div class="col-lg-7 mt--20 mt_md--40 mt_sm--40">
						<div class="content">
							<div class="inner">
								<h4 class="title"><strong>一键投屏</strong></h4>
								<div class="description" style="font-size: 18px">
									<div>电脑设备可以通过硬件辅助设备连接一键投屏访问无线投屏系统，无需数据线和转接口，即可将电脑上的内容一键上大屏。</div>
									<div class="items">
										<ul class="feature-list">
											<li class="adv">
												<div class="icon">
													<Icon name="check" size="16" icon-class="icon"/>
												</div>
												<div class="title-wrapper">
													<h4 class="title">让企业会议更方便</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon">
													<Icon name="check" size="16" icon-class="icon"/>
												</div>
												<div class="title-wrapper">
													<h4 class="title">让游戏体验更震撼</h4>
												</div>
											</li>
											<li class="adv">
												<div class="icon">
													<Icon name="check" size="16" icon-class="icon"/>
												</div>
												<div class="title-wrapper">
													<h4 class="title">让观影场景更享受</h4>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-5">
						<img class="product-img" src="../assets/images/cherdrop/d2.png" style="width: 100%" />
					</div>
				</div>
			</div>
		</div>


	</Layout>
</template>

<script>
import Layout from '../components/common/Layout.vue'
import Separator from "@/components/elements/separator/Separator.vue";
import Icon from "@/components/icon/Icon.vue";

export default {
	name: 'CheerDrop',
	components: {Icon, Separator, Layout},
	data() {
		return {
			productData: [
				{
					title: '前端显示自定义设计',
					image: require(`@/assets/images/cherdrop/a1.png`)
				},
				{
					title: '使用网页投屏，灵活简单',
					image: require(`@/assets/images/cherdrop/a2.png`)
				},
				{
					title: '投屏使用响应快、零延时',
					image: require(`@/assets/images/cherdrop/a3.png`)
				},
				{
					title: '支持SaaS部署、本地部署',
					image: require(`@/assets/images/cherdrop/a4.png`)
				}
			],
		}
	}
}
</script>
<style lang="scss">
.service {
	padding: 12px !important;
	.container {
		height: 240px;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-top: 40px;
		&.no-bg {
			background-color: rgb(248, 249, 250);
		}
		.title {
			font-size: 36px;
			color: white;
			line-height: 160px;
		}
		.img {
			width: 60px;
			height: 60px;
		}
		.content {
			color: #0f0f11;
			font-size: 22px;
			text-align: center;
			margin: 16px 12px 0 12px;
		}
	}
}
.items {
	div {
		margin-top: 10px;
	}
}
table {
	border-radius: 4px;
	thead {
		th {
			text-align: center;
			padding: 12px !important;
			font-size: 20px;
			vertical-align: middle;
		}
	}
	tbody {
		td {
			border-color: rgb(222, 226, 230) !important;
			height: 100%;
			vertical-align: middle;
		}
		.func-description {
			padding: 8px 12px;
			.func-title {
				font-weight: bold;
				margin-bottom: 8px;
			}
			li {
				font-size: 16px;
			}
		}
		.func {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
	}
}
.content-drop {
	color: #0f0f11;
	font-size: 22px;
	text-align: center;
	margin: 16px 12px 0 12px;
}
</style>
